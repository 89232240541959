.slick-track {
  display: flex;
  float: left;
}
.slick-slider {
  display: flex;
  align-items: center;
  /* max-width: 1200px; */
  max-width: 100%;
  margin: 0 auto;
}

.slick-slide {
  padding-right: 10px;
}

.slick-slide:last-child {
  padding-right: 0;
}
.slick-slide img {
  display: block;
}
.slick-list {
  margin: 0 15px;
  width: 100%;
}

.slick-prev {
  width: 50px;
  left: -24px;
  top: 47%;
  position: absolute;
  height: 50px;
  background: transparent;
  z-index: 1;
  border-radius: 50%;
  box-shadow: none;
}

.slick-next {
  width: 50px;
  right: -24px;
  top: 47%;
  position: absolute;
  height: 50px;
  background: transparent;
  z-index: 1;
  border-radius: 50%;
  box-shadow: none;
}
.slick-prev:before, .slick-next:before {
  opacity: 1;
  color: #3f7e44;
}

.slick-prev:focus,
.slick-prev:hover,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.product-actions {
  display: flex;
  justify-content: space-between;
}