nitin custom css for input box

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal-open .billing-model .container-fluid,
.modal-open  .billing-model .container {
    -webkit-filter: blur(5px) grayscale(90%);
}

/* nitin custom css for input box */

/* // PAVAN CUSTOM CSS */
#register input.right-place ~ span:before {
  content: attr(url);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translate(-38px, 35px);
  height: -moz-fit-content;
  height: fit-content;
}

#register .accordion-header button.accordion-button span {
  font-size: 12px;
  margin-top: 6px;
  width: 95%;
}

#register label.tools-img {
  box-shadow: 0px 0px 8px -5px #000;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #fff;
  cursor: pointer;
}

#register input:checked ~ label.tools-img {
  border: 2px solid #33693a;
}

.mdi-close-btn {
  font-size: 26px;
  line-height: 1;
  cursor: pointer;
  color: #f46a6a;
}
.mdi-add-btn {
  font-size: 26px;
  line-height: 1;
  cursor: pointer;
  color: #3C7C44;
}

.mdi-add-btn-50 {
  font-size: 40px;
  line-height: 1;
  cursor: pointer;
  color: #3C7C44;
}

#register .accordion-header button.accordion-button span:first-child {
  width: 20px;
  height: 20px;
  background: #3c7c44;
  text-align: center;
  color: #fff;
  border-radius: 100%;
  line-height: 22px;
  font-size: 14px;
  margin-top: 0;
  margin-right: 10px;
}

#register .steps .nav-item a.nav-link {
  display: flex;
  align-items: center;
  border-radius: 8px;
}

#register .steps .nav-item a.nav-link label {
  font-size: 1.2rem;
}

#register .wizard .steps .number {
  margin-right: 0.8rem;
  width: 30px;
  height: 30px;
  line-height: 28px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:active,
.wizard > .actions .disabled a:hover {
  pointer-events: none;
}

#register .close-btn {
  position: absolute;
  top: -10px;
  left: 97.5%;
}

#register .next-prev-btn {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 12px;
  background: #ffffff;
  width: 100%;
  text-align: center;
  margin: 0 auto !important;
  height: 60px;
  border-top: 1px solid #ebebeb;
}

.profile .next-prev-btn {
  top: 96% !important;
}

.profile .close-btn {
  left: 96.5% !important;
}

#register .complete-step {
  background-color: #3c7c44;
  color: #fff !important;
}

#sebiReport .steps .nav-item {
  margin-top: 5px;
  margin-bottom: 6px;
}

#sebiReport .steps .nav-item a.nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  height: 48px;
}

.dis-flex {
  display: flex;
}

.wizard.vertical-wizard .steps {
  transition: all 0.2s ease-in;
}

.react-tel-input.form-control {
  padding: 0 2rem 0px 0px;
}

.react-tel-input .form-control {
  width: 100%;
  border: none;
}

.inner-div:before {
  content: attr(count);
  position: absolute;
  left: 5px;
  background: #d8e5da;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #3c7c44;
  border-radius: 100%;
  top: -7px;
  right: 0;
}

#register .wizard.vertical-wizard .steps > ul > li {
  border: 1px solid #d8e5da;
  border-radius: 10px;
  margin-bottom: 10px;
}

.invalid-feedback ~ .invalid-msg {
  display: none;
}

.valid-msg {
  color: #34c38f;
  font-size: 90%;
  margin-top: 0.25rem;
}

.invalid-msg {
  color: #f46a6a;
  font-size: 90%;
  margin-top: 0.25rem;
}

#register select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Remove default arrow */
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAbklEQVRIie2PMQqAMAxFXwfP6qJVPIjYzcOKdUkhQ0BaCoLkLS3k818CjuN8ztApYzIDl7xvmalFsAIZuIFozKPMMrC0CAJwKIkuGVX5KdkmLEm3ci1JSlLKU49yLSmXVG1es0EANvnvInKcP/AA784fpjlWwNQAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(1em + 0.7rem) calc(1em + 0.7rem);
}

.tooltip-stack {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 8px;
}

.tooltip-stack .dripicons-question {
  height: 15px;
  margin-right: 5px;
}

.password ~ span {
  position: absolute;
  top: 0;
  right: 22px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(28px);
  width: 35px;
  font-size: 16px;
  cursor: pointer;
}

.copyright-text {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.swal2-html-container {
  overflow: hidden !important;
  margin: 0.3em 0em 0.3em !important;
}

.swal2-close {
  position: absolute;
  top: -6px;
  right: -2px;
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.labelContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

.propertyContainer {
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

.box-data {
  margin-top: 10px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  cursor: pointer;
}

.box-add {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.box-add i {
  font-size: 30px;
  color: #ccc;
  text-align: center;
  line-height: 30px;
}

.box-add label {
  font-size: 20px;
  color: #ccc;
  margin-bottom: 0;
  line-height: 30px;
  cursor: pointer;
}

.header-profile-user.user-avatar-img {
  height: 30px;
  width: 30px;
  padding: 3px;
  display: inline-block;
  line-height: 26px;
}

.tab-box {
  border-radius: 10px;
  border: 1px solid #d8e5da;
  height: 100%;
}

.tab-heading {
  background: #d8e5da;
  border-radius: 6px;
  padding: 10px 20px;
  display: block;
}

#sebiReport .wizard .steps .current a,
#sebiReport .wizard .steps .current a:active,
#sebiReport .wizard .steps .current a:hover {
  background-color: #008b0b4d !important;
}

#sebiReport .wizard .steps > ul > li a {
  background-color: rgb(255 255 255);
  border: 1px solid #add7b2;
}

#sebiReport .form-control,
#sebiReport .form-select {
  border-radius: 0.65rem;
}

/* END CSS PAVAN */

/* Bhoutik */
.switch.btn {
  width: 70px;
  min-height: 22px !important;
  border-radius: 30px;
}

.switch-off.btn {
  padding-left: 1.3rem !important;
}

.switch-on.btn {
  padding-right: 1rem !important;
}

.switch-group {
  padding: 0;
}

.switch-group span {
  padding: 0px;
}

.switch .switch-handle {
  width: 42px;
  border-radius: 50px;
}

.dots-icon {
  background: #3c7c441f;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  padding: 0;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
}

/*  */

.Titel1 {
  font-size: 18px;
}

.line {
  line-height: 40px;
  font-size: 15px;
}

.line li {
  margin: 15px;
}

.keyAdd {
  color: #c2c1c1;
}

.counternumber {
  font-size: 12px !important;
}

.fullbg {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.Lefttital {
  margin: 0px auto;
  line-height: 100px;
  font-size: 34px;
  font-weight: 500;
  color: #fff;
}

.avatar-xs1 {
  height: 40px;
  width: 40px;
  font-size: 17px;
  font-weight: 700;
}

.keymar {
  margin-top: 10px;
}

.keyclose {
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
}

.paddall {
  padding: 2px !important;
  border-radius: 27px !important;
}

.roundnumber {
  color: #fff;
  background-color: #f60;
}

.countNumber {
  background-color: #50a5f1 !important;
}

.butto {
  display: flex;
  justify-content: flex-end;
}

.reportname {
  font-size: 25px !important;
  margin-bottom: 25px !important;
  margin-top: 30px !important;
}

.reportname1 {
  font-size: 25px !important;
  margin-bottom: 30px !important;
  margin-top: 10px !important;
}

.but {
  display: flex;
  justify-content: flex-start;
}

.but1 {
  display: flex;
  justify-content: flex-end;
}

.accordion-item {
  border: none !important;
}

.inputheding {
  font-size: 20px !important;
  padding: 5px;
  margin: 10px;
}

.accordion-button:not(.collapsed) {
  /* color: #4d63cf; */
  background-color: #ffffff !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.categories-list li a {
  display: block;
  padding: 8px 12px;
  color: #495057;
  font-weight: 500;
  font-size: 16px;
  font-weight: 600;
}

.checkonput {
  margin-top: -1px;
  margin-left: 10px;
}

.hrddingtypr {
  font-size: 16px !important;
  margin-left: 17px;
}

/* 
.form-check-input {
    margin-top: -13px !important;
    width: 15px !important;
    height: 15px !important;
} */

.subchekc {
  margin-top: 0px !important;
  width: 15px !important;
  height: 15px !important;
  margin-right: 10px;
}

.butflex {
  display: flex;
  justify-content: flex-end;
}

.threebox {
  background-color: #eee;
  margin-top: 18px;
}

.linktext {
  color: #000;
}

.keyheding {
  font-size: 23px;
  font-weight: 500;
}

.keylistitem {
  border: 1px solid rgb(238, 238, 238);
  background: rgba(219, 219, 219, 0.1);
  margin-top: 15px;
}

.keyborder {
  border: 2px solid rgb(60 124 68 / 47%) !important;
}

.keytext {
  color: #999999;
  font-size: 14px;
  margin-top: 10px;
}

.keyclodeicon {
  color: red;
}

.keyborder {
  border: none !important;
  background: transparent;
}

.keycheckpoint {
  display: none;
}

.keypointlable {
  cursor: pointer;
}

.box-data {
  margin-top: 10px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  cursor: pointer;
}

.box-add {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.box-add i {
  font-size: 30px;
  color: #ccc;
  text-align: center;
  line-height: 30px;
}

.box-add label {
  font-size: 20px;
  color: #ccc;
  margin-bottom: 0;
  line-height: 30px;
  cursor: pointer;
}

.box-name {
  display: block;
  border: 1px solid #ccc;
  margin-top: 15px;
}

.box-name a {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.box-name ul {
  border-top: 1px solid #ccc;
}

.box-name ul li {
  padding-left: 20px;
  list-style-type: none;
  padding-top: 8px;
}

.box-name ul li:last-child {
  padding-bottom: 10px;
}

.main-sub-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #383c40;
  text-transform: uppercase;
  padding: 10px;
  border-bottom: 1px solid #eeee;
  margin-top: 5px;
  width: 100%;
}

.main-category-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.main-dis {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.add-category-input-box {
  display: flex;
  margin-top: 20px;
}

.add-category-btn {
margin-left: 10px;
}

.input-field-warning {
  color: rgb(206, 64, 64);
  /* padding: 5px; */
  margin-top: 5px;
}

.sweet-alert-container {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 0.4rem;
  outline: 0;
}

.sweet-alert-popup {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  position: relative;
  flex: 1 1 auto;
}

.sweet-alert-icon {
  font-size: large;
  font-weight: 900;
  color: orange;
}

.swal2-confirm {
  background-color: red;
}

.sweet-alert-confirmbutton {
  text-align: center !important;
  margin-top: 1rem !important;
}

.sweet-alert-title {
  color: #495057;
  font-size: 1.21875rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.data-accordion .accordion-button::after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus'%3E%3Cline x1='12' y1='5' x2='12' y2='19'%3E%3C/line%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  opacity: 0.7;
}

.data-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  opacity: 0.7;
}

.data-accordion .accordion-button {
  position: relative;
}

.badge-inner {
  margin-left: auto;
  background: rgb(51, 105, 58) !important;
  position: absolute;
  right: calc(100% - 88%);
}

.data-accordion ul li {
  margin-bottom: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.reding-value {
  line-height: 1.6;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}

.h-534 {
  height: 533px;
}

.search-input {
  margin-bottom: 15px;
}

.border-box ul {
  padding-left: 0px;
}

.border-box ul li {
  list-style-type: none;
}

.box-add {
  border: 1px dashed #ccc;
  padding: 10px;
  border-radius: 10px;
}

.box-add i {
  font-size: 30px;
  margin: 0 auto;
  display: block;
  color: #ccc;
  text-align: center;
}

.box-add label {
  text-align: center;
  font-size: 16px;
  color: #ccc;
  margin: 0 auto;
  display: block;
}

@media only screen and (max-width: 1300px) {
  .badge-inner {
    right: calc(100% - 80%);
  }
}

#listitem-pading-0 {
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
#listitem-pading-0:hover{
  background-color: rgb(238, 236, 236);
}
.list-active{
  background-color: rgb(238, 236, 236);
}

#listitem-pading {
  padding: 0.5rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #212529;
}

#Border-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.scroll{
  max-height: 70vh;
  overflow-y: scroll;
}

.text-capital {
  text-transform: uppercase;
}

.icon-size {
  font-size: 1rem;
  font-weight: 900;
  color: #212529;
}

.red {
  color: red;
}

.tableEmpty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.nav-link.disabled {
  background-color: #ccd2d7 !important;
  border-color: #ccd2d7 !important;
}
.positi {
  position: fixed;
}
#sidebar-menu {
  padding: 10px 0 30px 0;
  /* position: fixed; */
  /* width: 249px; */
}
.fixed {
  background-color: #e3eee3;
  padding: 14px;
  font-size: 14px;
  border-radius: 5px;
}
/* .sidebar-enable .is-sticky {
  right: 45px;
  left: 294px;
} */
/* .is-sticky {
  position: fixed;
  top: 70px;
  z-index: 99999;
  left: 114px;
  right: 45px;
} */
.is-sticky {
	position: sticky;
	top: -24px;
	z-index: 1;
	position: -webkit-sticky;
}
.marginTop {
  margin-top: 4rem !important;
}

/* @media (min-width: 992px){
.col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
}
} */
/* 
style={{
  position: "fixed",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  width: "83%",
  backgroundColor: "FFFFFF",
  padding: "10px"
}} */


/*p1*/

/* style={{
  position: "fixed",
  marginBottom: "1rem",
  padding: "10px 5px 10px 5px",
  backgroundColor: "#add7b2",
  wordWrap: "break-word"
}} */


/* profile image avatar css by pv */
.proImgMain {
  margin: 0px auto;
  max-width: 8.5rem;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
}
.proAvatarName {
    width: 8.5rem;
    height: 8.5rem;
    display: block;
    text-align: center;
    line-height: 8.5rem;
    font-size: 50px;
}
label.proImgLabel {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #00000075;
  margin: 0;
  font-size: 28px;
  transform: translateY(80px);
  cursor: pointer;
  transition: all .2s ease;
}
.proImgMain:hover label.proImgLabel {
  transform: translateY(0px);
}




.leadership{
background-color: #3F7E44;
padding: 10px 5px 10px 5px;
border-radius: 5px;
color: white;

}

/*************New CSS***********/
/* .pos-stiky {    position: fixed;
  background: var(--bs-body-bg);
  width: 100%;
  z-index: 9;
  top: 70px;
  padding: 10px;}
  .mt-50 {margin-top: 50px;}
  .right-85 {position: relative; right: calc(100% - 85%);}
  .card-title {margin-top: 10px; margin-bottom: 0 !important;} */
  .header {width: 100%; background-color: #fff;padding: 20px;}
.h-67 {height: 65vh; overflow-x: auto;}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3c7c44; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3c7c44; 
}
#sidebar-menu .has-arrow:after {
  left: unset !important;
  position: absolute;
  right: 12px;
  top: 20%;
  z-index: 9999;
}
.vertical-collpsed #sidebar-menu {
  position: fixed;
}
/* @media only screen and (max-width: 1360px) {} */
@media only screen and (max-width: 1360px) {
  /* .steps {height: 74vh; overflow-x: auto ;margin-bottom: 55px;} */
  .steps ul {margin-right: 13px; }
  #sidebar-menu .has-arrow:after {right: -12px; position: relative;}
  .vertical-menu {width: 215px;}
  .navbar-brand-box {padding: 0px 10px; width: 0px;}
  #page-topbar, .footer {left: 215px;}
  /* #sidebar-menu {width: 215px;} */
  .main-content {margin-left: 215px;}
  #sebiReport .steps .nav-item a.nav-link {height: auto;}
  .h-67 {height: 60vh;}
  #sebiReport {margin-bottom: 50px;}
}

.font{
  font-size: smaller;
  color: rgb(235, 104, 104);
}

.carbonform_heading{
  color: #3C7C44;
  text-decoration: underline;
  text-transform: capitalize; 
}
.carbonform_discription{
 font-weight: 600;
 color: rgb(91, 90, 90);
 text-align: justify 
}

/* .zIndex{
  z-index: 4;
} */

@media (min-width: 1400px){
  .dropdown-menu-md {
      width: 300px;
  }
  #sidebar-menu .has-arrow:after {
    left: unset;
    position: absolute;
    right: 12px !important;
    top: 20%;
    z-index: 9999;
  }
  }

@media (min-width: 1718px){
.dropdown-menu-md {
    width: 400px;
}
#sidebar-menu .has-arrow:after {
  left: unset;
  position: absolute;
  right: 12px !important;
  top: 20%;
  z-index: 9999;}
}


[pointer-events="bounding-box"] {
  display: none
}

/* Admin Dashboard CSS*/
.Cardbodyheight{
  height: 271px;
}
.CardbodyBillingheight{
  height: 313px;
}
.chartheight{
  height: 612px;
}
.leftMar{
  margin-left: 10px;
  font-weight: 800;
  margin-right: 15px;
  color: #33693a;
}
.linewith{
  width: 98% !important;
}
/* .sub-menu li a:hover{
  background-color: #aff2b8;
} */

/* .vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a > ul > li >a:hover{
  background-color: #aff2b8;
} */
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #383c40;
  background-color: #aff2b8;
}

.text-align{
  text-align: center;
  vertical-align: middle;
  }
.text-align-left{
  text-align: left;
  vertical-align: middle;
  }

  .price-tab .nav-justified .nav-item {flex-grow: 0.2;}
  .price-tab .nav-tabs-custom {border-bottom: 2px solid #e3e3e3;}
  .price-tab {padding: 0 30px;}
  .price-tab .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {background-color: transparent !important;}

  .p-20{
    padding: 4%;
  }

  .wrapper:before,.wrapper:after{
  content:" ";
  width: 41%;
  height: 2px;
  vertical-align: middle;
  background-color:rgb(217, 214, 214);
  display:inline-block;
  padding: 0;
  margin: 0;
}
.integrationCom{
  transition: 0.2s;
}
.integrationCom:hover{
  box-shadow: 0px 0px 5px 5px rgb(0 0 0 / 10%);
    cursor: pointer;
    
}
.center-modal textarea.form-control { min-height: calc(8.5em + 0.94rem + 2px);}
 .activity {
  border-radius: 4px;
  color: rgb(83, 83, 83);
  margin-top: 5px;
  margin-right: 10px;
}
#sebiReport .form-control, #sebiReport .form-select{
  overflow: hidden;
}


.view-edit-box-icon{
  cursor: pointer;
  font-size: large;
  float: right;
}


.next-previous-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#resendLink:hover {
  text-decoration: none;
  border: none;
}
#resendLink:disabled {
  border: none;
}
.react-activity{
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-b{
  border-bottom: 1px solid #eff2f7 !important;
  padding-bottom: 5px;
}

.factor_value_box{
  background-color: #33693a;
  color: white;
}

/*krishna css*/

.table-box {
  position: relative;
  display: flex;
}
.table-box .card-table {
  width: 100%;
  height: 100%;
  padding: 1px 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  border: 1px solid #aad863;
  transition: all 0.2s ease-in-out;
}
.table-box .card-table:nth-child(1) {
  background: #7f7fd5;
  background: linear-gradient(to left, #f6fcf1, #e3f8ca);
height: 170px;
cursor: pointer;
}
.table-box .card-table:nth-child(1):before {
  content: url('./images/business-report.png');
  animation: text-hover 5s ease-in-out infinite normal;
  position: relative;
}
.table-box .card-table.box-second:before {
  content: url('./images/increasing-copy.png');
  animation: text-hover 5s ease-in-out infinite normal;
  position: relative;
}
.table-box .card-table.box-third:before {
  content: url('./images/carbon-footprint-white.png');
  animation: text-hover 5s ease-in-out infinite normal;
  position: relative;
}
.table-box .card-table:hover {
  transform: scale(1.02);
  background: linear-gradient(to left, #e3f8ca,  #f6fcf1);
}

/* #select-row{
  width: 10%;
} */

.table-box .card-table .contain-box-top {display: flex; width: 100%; justify-content: space-between; position: absolute; padding: 17px 20px; top: 5px;}
@keyframes text-hover {
  0% {
      left: -30%;
 }
  50% {
      left: -37%;
 }
  100% {
      left: -30%;
 }
}
.mt-2 {margin-top: 2rem !important;}
.mb-06 {margin-bottom: 0.6rem !important;}
.contain-box-top h1 {color: #3d7d42;}
.contain-box-top h3 {color: #4c9f38; font-size: 30px;}
.contain-box-top  .btn, .location-list .btn, .user-btn .btn, .table-btn .btn {
display: inline-block;
font-weight: 400;
line-height: 1.5;
color: #495057;
text-align: center;
vertical-align: middle;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
background-color: transparent;
border: 1px solid transparent;
padding: 5px .75rem;
font-size: .8125rem;
border-radius: 9px;
-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  top: 7px;
}

.contain-box-top .btn-primary, .location-list .btn-primary, .user-btn .btn-primary, .table-btn .btn-primary {
color: #fff;
background-color: #3d7d42;
border-color: #3d7d42;
}

.contain-box-top .btn-primary:hover, .location-list .btn-primary:hover, .user-btn .btn-primary:hover, .table-btn .btn-primary:hover {
color: #fff;
background-color: #4c9f38;
border-color: #4c9f38
}
.contain-box-top .waves-effect, .location-list .waves-effect, .user-btn .waves-effect, .table-btn .waves-effect {
position: relative;
cursor: pointer;
display: inline-block;
overflow: hidden;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-tap-highlight-color: transparent
}
.contain-box-top .waves-effect.waves-light .waves-ripple, .location-list .waves-effect.wave-light .wave-ripple, .user-btn .waves-effect.wave-light .wave-ripple, .table-btn .waves-effect.waves-effect.wave-light .wave-ripple {
background: rgba(255, 255, 255, .4);
background: radial-gradient(rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .3) 40%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, 0) 70%)
}
.contain-box-top .waves-effect.waves-classic.waves-light .waves-ripple, .location-list .waves-effect.waves-classic.wave-light .wave-ripple, .user-btn .waves-effect.wave-light .wave-ripple, .table-btn .waves-effect.wave-light .wave-ripple {
background: rgba(255, 255, 255, .4)
}
.mt-15 {margin-top: 15% !important;}
.title h5 {border-bottom: 1px solid #ccc; padding-bottom: 16px;}
.location-box img {
  -webkit-animation: location 1s infinite  alternate;
  animation: location 1s infinite  alternate;
}
@-webkit-keyframes location {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

@keyframes location {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
.location-list .dripicons-location {text-align: center; font-size: 30px; color: #3d7d42; margin: 0 auto; display: block; margin-top: 20px; font-weight: 600;}
.location-list p {text-align: center; font-size: 20px;}
.location-name {position: relative; display: flex; padding-left: 10px; flex-wrap: wrap; width: 100%; }
.location-list span { border: 1px dashed #7d7d7d; padding: 5px 5px; color: #7d7d7d; border-radius: 5px; cursor: pointer; margin-right: 10px; margin-top: 10px;}
.location-name  a { margin-left: auto; margin-top: 18px; color: #3d7d42; font-size: 14px; font-weight: 600; text-decoration: underline !important;}
.location-list .text-right {float: right; margin-top: 30px;}
.user-btn .btn {margin-bottom: 20px; float: right;}
.location-connect {height: 416px; overflow-y: auto;}
.table-btn {padding: 0px !important;}
.pull-center {text-align: center;}
.active-chart .e-charts {height: 280px;}
.btn-add-location {position: absolute; width: 100%; bottom: -70px; right: 10px;}
.h-315 {height: 375px; overflow-y: auto;}
.location-box img {width: 100%;}

@media only screen and (max-width: 1600px){
.table-box .card-table:nth-child(1) {height: 100%;}
.mt-15 {margin-top: 25% !important;}
.location-box img {width: 100%; position: relative; top: 73px;}
.location-name {padding-left: 0px;}
.location-connect {height: 416px; overflow-y: auto;}
.btn-add-location {position: absolute; margin-top: 30px;}
.location-list .text-right {margin-top: 10px;}
/* .location-connect .col-xl-7, .active-box .col-xl-8 {width: 100% !important;}
.location-connect .col-xl-5 img, .active-box .col-xl-4 img{display: none;} */
.user-btn .btn {position: relative; top: 25px;}
.w-50 {width: 40% !important;}
.contain-box-top h1 {font-size: 22px;}
.contain-box-top h3 {font-size: 20px; margin-top: 8px;}
.location-list p {margin-bottom: 10px;}
/* .location-box img {width: 85%;} */
}

@media only screen and (max-width: 1200px){
.table-box .card-table:nth-child(1) {height: 100%;}
.table-box {margin-bottom: 20px;}
.mt-15 {margin-top: 9% !important;}
.location-connect {height: 100%;}
.location-box img {display: block; margin: 0 auto; text-align: center;}
.btn-add-location {position: relative; bottom: 0;}

#select-row{    
  width: 15%;
}
}

/*Laptop Media Quriy*/

@media screen and (min-width : 768px) and (max-width:1024px){
  .laptopmedialocation{
    display: flex;
    justify-content: center;
    align-items: center;
    top: 39px;
  }
  .companydetails{
    width : 16% !important;
  }
  .laptopmedia{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #sidebar-menu .has-arrow:after{
    /* left: 14px; */
    left: 0px !important;
  }
  .table-box .card-table .contain-box-top{
    padding: 8px 20px;
  }
  .table-box .card-table:nth-child(1):before {
    position: relative;
    top: 3px;
  }
  .mx-auto{
    width: 100%;
  }
  /* .add-location-btn {position: absolute;right: 14px; bottom: -161px;} */
}


@media screen and (min-width : 1030px) and (max-width:1366px){
  .laptopmedialocation{
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
  }
  .companydetails{
    width : 16% !important;
  }
  .laptopmedia{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #sidebar-menu .has-arrow:after{
    /* left: 14px; */
    left: 0px !important;
  }
  .table-box .card-table .contain-box-top{
    padding: 8px 20px;
  }
  .table-box .card-table:nth-child(1):before {
    position: relative;
    top: 3px;
  }
  /* .add-location-btn {position: absolute;right: 14px; bottom: -76px;} */
  .wrapper:before,.wrapper:after{
    content:" ";
    width: 34%;
    height: 2px;
    vertical-align: middle;
    background-color:rgb(217, 214, 214);
    display:inline-block;
    padding: 0;
    margin: 0;
  }
  .mx-auto{
    width: 100%;
  }
  #select-row{    
    width: 15%;
  }
  
}


@media screen and (min-width : 1370px) and (max-width:1605px){

  /* .add-location-btn {
    position: absolute;
    right: 14px;
    bottom: -74px;
  } */
  .table-box .card-table{
    padding: 10px 15px;
  }
  .table-box .card-table .contain-box-top{
    padding: 7px 20px;
  }
  .wrapper:before,.wrapper:after{
    content:" ";
    width: 36%;
    height: 2px;
    vertical-align: middle;
    background-color:rgb(217, 214, 214);
    display:inline-block;
    padding: 0;
    margin: 0;
  }
  .mx-auto{
    width: 100%;
  }
}


@media screen and (min-width : 1610px) and (max-width:2000px){
/* .add-location-btn {
  position: absolute;
  right: 14px;
  bottom: -5px;
} */

}

.location-name {
  max-height: 134px;
  overflow-y: auto;
  scrollbar-color: #3f7e44 #3f7e44;
  scrollbar-width: thin;
}
.fixheight{
  height: 100%;
}
.top-9 {position: relative; top: 9px;}
.text-right {text-align: right;}
.table-bordered td, .table-bordered th {border-color: #e1e1e1;}
.text-underline {text-decoration: underline;}
.dis-right {display: flex; margin-left: auto;}
.dis-center {display: flex; margin-left: auto; justify-content: center;}
.txt-decoration {text-decoration: underline;}
.bordr-bottom {border-bottom: 1px solid #eff2f7;}
.w-50 {width: 50%;}
.space-between {justify-content: space-between;}
.ml-10 {margin-left: 10px !important;}
.box-border {
	border: 1px solid #dedede;
	margin: 20px 0;
	border-radius: 5px;
	padding: 15px;
}


.user-btn {
	position: absolute;
	bottom: 18px;
	right: 14px;
}


.integrationCom {
  border-radius: 8px;
}
.integrationCom .card-body {
  border: 3px solid #3c7c4438;
  border-radius: 8px;
  transition: all .3s ease-in-out;
}
.integrationCom .card-body:hover {
  border-color: #3c7c44;
}
.img-div:before {
  content: "Coming Soon";
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  background: #3c7c44;
  font-size: 16px;
  text-transform: uppercase;
  padding: 2px 10px;
  top: -12px;
  border-radius: 4px;
  right: 5%;
  z-index: 9;
  letter-spacing: 2px;
  color: #fff;
  box-shadow: 0px 5px 14px -6px #3c7c44;
  transition: all .3s ease-in-out;
}

.integrationCom .card-body img {
  filter: grayscale(1) blur(3px);
  transition: all .3s ease-in-out;

}
.integrationCom .card-body:hover img{
  filter: grayscale(0) blur(0px);
}


.font-size-50{
  font-size: 100px;
}

.add_doc{
  margin-bottom: 24px;
}
.leftbutton{
  left: -25px;
}
.mb-20 {
  margin-bottom: 20px;
}
.applybutton {
  margin-top: 28px !important
}
.datatype-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f46a6a;
  font-weight: 500;
}
.subsectionCapital {
  text-transform:capitalize !important;
}
.displayNone{
  display: none !important;  
}
.tree-view ul li p, .tree-view ul li span {
  cursor: pointer;
}
.backbtn:focus{
  border: none !important;
}
.zoomButton{
  top: 11px;
  right: -5px;
  font-size: 22px;
  position: absolute;
  cursor: pointer
}
.zoomBackButton{
  font-size: 22px;
  position: absolute;
  top: 12px;
  right: 12.5%;
  cursor: pointer
}